import { Injectable, inject } from '@angular/core';
import {
  GetProfilePlaylistsGQL,
  Playlist,
  PlaylistStatus,
} from '@designage/gql';
import { CurrentUserService } from '@desquare/services';
import { GraphQLFormattedError } from 'graphql';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PlaylistService {
  statuses: PlaylistStatus[] = [
    PlaylistStatus.Draft,
    PlaylistStatus.Published,
    PlaylistStatus.ReadyToPublish,
  ];

  private getProfilePlaylistsGql$ = inject(GetProfilePlaylistsGQL).watch(
    {
      profileId: inject(CurrentUserService).getCurrentProfileId(),
      statuses: this.statuses,
      onlyParentPlaylist: true,
    },
    { fetchPolicy: 'cache-and-network' }
  );

  getProfilePlaylistsFromApi$(): Observable<{
    playlists: Playlist[];
    loading: boolean;
    errors: readonly GraphQLFormattedError<Record<string, any>>[] | undefined;
  }> {
    return this.getProfilePlaylistsGql$.valueChanges.pipe(
      map(({ data, loading, errors }) => {
        const playlists = data.profile?.playlists as Playlist[];
        return { playlists, loading, errors };
      })
    );
  }

  refreshPlaylistsFromApi() {
    this.getProfilePlaylistsGql$.refetch();
  }
}
