import {
  signalStore,
  withState,
  withComputed,
  withMethods,
  patchState,
  withHooks,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { tapResponse } from '@ngrx/operators';
import {
  ChannelService,
  PlaylistService,
  ToasterService,
} from '@desquare/services';
import { Signal, computed, inject, signal } from '@angular/core';
import { distinctUntilChanged, pipe, switchMap } from 'rxjs';
import { GraphQLFormattedError } from 'graphql';
import { Channel, Playlist } from '@designage/gql';
import { IPlaylistRegion } from '@desquare/interfaces';
import { ApolloError } from '@apollo/client';

export interface IPlaylistsSignal {
  playlists: Playlist[];
  loading: boolean;
  errors: GraphQLFormattedError<Record<string, any>>[] | null;
}

const initialState: IPlaylistsSignal = {
  playlists: [],
  loading: true,
  errors: null,
};

export const PlaylistsStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),

  withMethods((store, playlistService = inject(PlaylistService)) => ({
    getPlaylistsFromApi: rxMethod<void>(
      pipe(
        distinctUntilChanged(),
        switchMap(() => {
          return playlistService.getProfilePlaylistsFromApi$().pipe(
            tapResponse({
              next: ({ playlists, errors, loading }) => {
                patchState(store, {
                  playlists,
                  loading,
                  errors: errors ? [...errors] : [],
                });
              },
              error: console.error,
              finalize: () => patchState(store, { loading: false }),
            })
          );
        })
      )
    ),
    getPlaylist: (playlistId: string): Signal<Playlist | undefined> => {
      const playlist = signal<Playlist | undefined>(
        store.playlists().find((x) => x.id === playlistId)
      );
      console.log('playlist', playlist());

      return playlist;
    },
    updatePlaylist: (playlist: Playlist) => {
      patchState(store, {
        playlists: store.playlists().map((x) => {
          if (x.id === playlist.id) {
            return { ...x, ...playlist };
          } else {
            return x;
          }
        }),
      });
    },
    addChannel: (playlist: Playlist) => {
      patchState(store, {
        playlists: [...store.playlists(), playlist],
      });
    },
    removeChannel: (playlistId: string) => {
      patchState(store, {
        playlists: store.playlists().filter((x) => x.id !== playlistId),
      });
    },
  })),
  withHooks((store) => ({
    onInit() {
      // Load products when the store is initialized
      store.getPlaylistsFromApi();
    },
  })),
  withComputed((state) => ({}))
);
