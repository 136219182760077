import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PlaylistListComponent } from '../playlist-list/playlist-list.component';

@Component({
  standalone: true,
  imports: [PlaylistListComponent],
  selector: 'app-playlist-list-dialog',
  template: `<div class="modal-container">
    <div class="modal-header">
      <button
        type="button"
        class="btn-close btn-close-white"
        aria-label="Close"
        data-dismiss="modal"
        (click)="modal.dismiss()"
      ></button>
    </div>
    <div class="modal-body">
      <app-playlist-list
        [channelId]="channelId"
        [locationId]="locationId"
        [channelGroupId]="channelGroupId"
        [showBulkActionControl]="false"
        [redirectOnSelect]="false"
        [enableCheckbox]="false"
        [enablePlaylistSelect]="false"
        [enableSlidePanel]="false"
      ></app-playlist-list>
    </div>
  </div> `,
})
export class PlaylistListDialogComponent {
  channelId!: string;
  locationId!: string;
  channelGroupId!: string;

  constructor(public modal: NgbActiveModal) {}
}
