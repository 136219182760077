import { Component } from '@angular/core';
import { NgbActiveModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import {
  PlaylistSaveDialogCloseReason,
  ChannelSelectionMode,
  ChannelFilters,
} from '@desquare/enums';
import { Maybe } from 'graphql/jsutils/Maybe';
import { Location, Channel, ChannelGroup } from '@designage/gql';
import { IPlaylistSaveDialogResult } from '@desquare/interfaces';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ChannelSelectionComponent } from '@designage/app/shared/channel-selection/channel-selection.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NgbTooltip,
    ChannelSelectionComponent,
  ],
  selector: 'app-playlist-saving-dialog',
  template: `<div class="modal-container">
    <div class="modal-header">
      <h4 class="modal-title">{{ 'PUBLISH_PLAYLIST' | translate }}</h4>

      <button
        type="button"
        class="btn-close btn-close-white"
        aria-label="Close"
        data-dismiss="modal"
        (click)="modal.dismiss()"
      ></button>
    </div>
    <div class="modal-body">
      <div
        class="container-fluid content-container-card channel-selection-container"
      >
        <h5 class="mb-1">{{ 'SELECT_CHANNELS_TO_PUBLISH_TO' | translate }}</h5>
        <app-channel-selection
          [(selectedChannels)]="selectedChannels"
          [(selectedLocations)]="selectedLocations"
          [(selectedChannelGroups)]="selectedChannelGroups"
          [profileId]="profileId"
          [channelSelectionMode]="channelSelectionMode.PUBLISHING"
          (selectedChannelFilterChange)="onChannelFilterChange($event)"
        ></app-channel-selection>
      </div>
    </div>
    <div class="modal-footer">
      <div class="col text-center">
        <button
          [hidden]="!showSaveButton"
          type="button"
          class="btn btn-outline-primary w-25 m-1"
          (click)="save()"
          ngbTooltip="{{ 'TITLE_SAVE_CURRENT_DRAFT' | translate }}"
        >
          {{ 'SAVE_CURRENT_DRAFT' | translate }}
        </button>
        <button
          [hidden]="!showCreateButton"
          type="button"
          class="btn btn-outline-warning w-25 m-1"
          (click)="newDraft()"
          ngbTooltip="{{ 'TITLE_CREATE_NEW_DRAFT' | translate }}"
        >
          {{ 'CREATE_NEW_DRAFT' | translate }}
        </button>
        <button
          [hidden]="!showPublishButton"
          type="button"
          class="btn btn-outline-success w-25 m-1"
          (click)="publish()"
          ngbTooltip="{{ 'TITLE_PUBLISH_PLAYLIST' | translate }}"
        >
          {{ 'PUBLISH_PLAYLIST' | translate }}
        </button>
      </div>
    </div>
  </div> `,
})
export class PlaylistPublishDialogComponent {
  // false if creating new playlist and updating a published playlist

  showSaveButton = true;
  showCreateButton = true;
  showPublishButton = true;
  profileId!: Maybe<string>;
  selectedChannels: Channel[] = [];
  selectedLocations: Location[] = [];
  selectedChannelGroups: ChannelGroup[] = [];
  channelSelectionMode = ChannelSelectionMode;
  channelFilter!: string;
  result!: IPlaylistSaveDialogResult;

  constructor(public modal: NgbActiveModal) {}

  save() {
    this.result = {
      option: PlaylistSaveDialogCloseReason.UPDATE,
      channels:
        this.channelFilter === ChannelFilters.CHANNELS
          ? this.selectedChannels
          : [],
      locations:
        this.channelFilter === ChannelFilters.LOCATIONS
          ? this.selectedLocations
          : [],
      // channelGroups:
      //   this.channelFilter === ChannelFilters.CHANNEL_GROUPS
      //     ? this.selectedChannelGroups
      //     : [],
    };

    this.modal.close(this.result);
  }

  newDraft() {
    this.result = {
      option: PlaylistSaveDialogCloseReason.DRAFT,
      channels:
        this.channelFilter === ChannelFilters.CHANNELS
          ? this.selectedChannels
          : [],
      locations:
        this.channelFilter === ChannelFilters.LOCATIONS
          ? this.selectedLocations
          : [],
      // channelGroups:
      //   this.channelFilter === ChannelFilters.CHANNEL_GROUPS
      //     ? this.selectedChannelGroups
      //     : [],
    };

    this.modal.close(this.result);
  }

  publish() {
    this.result = {
      option: PlaylistSaveDialogCloseReason.PUBLISH,
      channels:
        this.channelFilter === ChannelFilters.CHANNELS
          ? this.selectedChannels
          : [],
      locations:
        this.channelFilter === ChannelFilters.LOCATIONS
          ? this.selectedLocations
          : [],
      // channelGroups:
      //   this.channelFilter === ChannelFilters.CHANNEL_GROUPS
      //     ? this.selectedChannelGroups
      //     : [],
    };

    this.modal.close(this.result);
  }

  onChannelFilterChange(value: string) {
    this.channelFilter = value;
  }
}
